import { MenuItem, FormControl, Select, InputLabel } from "@mui/material";
import { useTheme } from "@mui/material";
import PropTypes from "prop-types";
import { getSubstring } from "utils";

const CHARACTER_LIMIT = 22;

const DropdownV1 = ({
  placeholder = "",
  listType = "object",
  itemList,
  displayKey,
  valueKey,
  menuStyle = {},
  value,
  label,
  labelStyle = {},
  sx,
  disabled,
  ...rest
}) => {
  const theme = useTheme();
  return (
    <FormControl fullWidth sx={{ padding: 0, ...sx }} disabled={disabled}>
      {label && (
        <InputLabel
          id="demo-simple-select-label"
          sx={{
            "&.MuiInputLabel-root": {
              top: -8,
            },
            "&.MuiInputLabel-shrink": {
              top: "unset",
            },

            ...labelStyle,
          }}
        >
          {label}
        </InputLabel>
      )}
      <Select
        value={getSubstring(value, CHARACTER_LIMIT) ?? ""}
        size="small"
        id="demo-simple-select"
        displayEmpty={true}
        label={label}
        renderValue={(val) => (val?.length ? val : placeholder)}
        MenuProps={{
          style: {
            maxHeight: 300,
          },
        }}
        sx={{
          "& .MuiSelect-outlined": {
            padding: 2,
            color:
              !value && placeholder
                ? theme.palette.text?.secondary
                : theme.palette.text?.primary,
            ...menuStyle,
          },
        }}
        {...rest}
      >
        {/* display list of constants to be shown  */}
        {listType !== "object"
          ? !!itemList &&
            itemList.map((item) => (
              <MenuItem
                value={item}
                key={item}
                style={{
                  maxWidth: 500,
                  whiteSpace: "normal",
                  wordWrap: "break-word",
                }}
              >
                {item}
              </MenuItem>
            ))
          : !!itemList &&
            itemList.map((item) => {
              return valueKey ? (
                <MenuItem value={item[valueKey]} key={item[valueKey]}>
                  {displayKey ? item[displayKey] : item[valueKey]}
                </MenuItem>
              ) : (
                <MenuItem value={item.value} key={item.value}>
                  {item.label}
                </MenuItem>
              );
            })}
      </Select>
    </FormControl>
  );
};

DropdownV1.propTypes = {
  placeholder: PropTypes.string,
  listType: PropTypes.string,
  displayKey: PropTypes.string,
  valueKey: PropTypes.string,
  label: PropTypes.string,
  disabled: PropTypes.bool,
  menuStyle: PropTypes.object,
  sx: PropTypes.object,
  labelStyle: PropTypes.object,
  itemList: PropTypes.array.isRequired,
  value: PropTypes.string.isRequired,
};
export default DropdownV1;

import { Grid } from "@mui/material";
import PropTypes from "prop-types";

const GridView = ({
  firstChild,
  secondChild,
  thirdChild,
  fourthChild,
  fifthChild,
  sixthChild,
  style,
}) => {
  return (
    <Grid container style={style}>
      <Grid item xs={2}>
        {firstChild}
      </Grid>
      <Grid item xs={2}>
        {secondChild}
      </Grid>
      <Grid item xs={2}>
        {thirdChild}
      </Grid>
      <Grid item xs={2}>
        {fourthChild}
      </Grid>
      <Grid item xs={2}>
        {fifthChild}
      </Grid>
      <Grid item xs={2}>
        {sixthChild}
      </Grid>
    </Grid>
  );
};

export default GridView;

GridView.propTypes = {
  firstChild: PropTypes.element,
  secondChild: PropTypes.element,
  thirdChild: PropTypes.element,
  fourthChild: PropTypes.element,
  style: PropTypes.object,
};

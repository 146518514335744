import { useCallback, useEffect, useRef, useState } from "react";
import { Box, useTheme } from "@mui/material";

import { Loader, UploadCSV } from "components";

import ReviewContent from "./ReviewContent";
import { Filters } from "./components";

import { postCall_v2 } from "services";
import { useToaster } from "hooks";

import { error, success } from "constants";

const rowsPerPage = 50;

const BulkShipment = () => {
  const [filters, setFilters] = useState({});
  const [bulkShipmentData, setBulkShipmentData] = useState({});
  const [page, setPage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileUploading, setIsFileUploading] = useState(false);

  const csvUploadRef = useRef();
  const triggerToaster = useToaster();
  const theme = useTheme();

  useEffect(() => {
    setIsLoading(true);
    fetchBulkShipments();
  }, [page, filters]);

  const fetchBulkShipments = async () => {
    try {
      const { data } = await postCall_v2(
        `/joms/api/external/v1/bulk-shipment/fetch-uploaded-jopl-shipment`,
        {
          offset: page * rowsPerPage,
          limit: rowsPerPage,
          filterDto: {
            invoiceNumber: filters?.invoiceNumber || null,
            customerGstin: filters?.customerGSTIN || null,
            plantCode: filters?.plantCode || null,
            soNumber: filters?.soNumber || null,
          },
        },
      );

      if (data) {
        setBulkShipmentData(data);
      }
    } catch (err) {
      triggerToaster(err?.data?.errorMessage || "Something went wrong!", error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleFilterSubmit = (filterData) => {
    setFilters(filterData);
  };

  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  /**
   * @description callback fn on file upload
   */
  const fileUploadCallback = async (file) => {
    setIsFileUploading(true);
    if (file) {
      try {
        const formData = new FormData();
        formData.append("file", file);
        const res = await postCall_v2(
          `/joms/api/external/v1/bulk/sap/upload`,
          formData,
        );
        if (res) {
          fetchBulkShipments();
        }
        if (res?.data) {
          triggerToaster(res?.data, success);
        }
      } catch (err) {
        triggerToaster(
          err?.data?.errorMessage || `Error occurred, please try again`,
          error,
        );
      } finally {
        setIsFileUploading(false);
        csvUploadRef?.current?.resetFile();
      }
    }
  };

  return (
    <Box>
      <UploadCSV
        headerText={"Bulk upload"}
        fileCallBackFn={fileUploadCallback}
        allowedMaxSize={5}
        ref={csvUploadRef}
        isLoading={isFileUploading}
        fileType={"xlsx"}
      />
      <Box
        style={{
          padding: 10,
          borderRadius: 12,
          border: `1px solid ${theme.palette.grey["400"]}`,
        }}
      >
        <Box mb={4}>
          <Filters
            onFilterSubmit={handleFilterSubmit}
            totalRecords={bulkShipmentData?.totalCount}
          />
        </Box>
        <ReviewContent
          bulkShipmentData={bulkShipmentData}
          onPageChange={handlePageChange}
          rowsPerPage={rowsPerPage}
          isLoading={isLoading}
        />
      </Box>
    </Box>
  );
};

export default BulkShipment;

import { useCallback, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";

import {
  Box,
  Table,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
  Paper,
  TableBody,
  TablePagination,
  useTheme,
} from "@mui/material";
import PropTypes from "prop-types";

import {
  ButtonV1,
  DatePickerV1,
  DropdownV1,
  Loader,
  PaginationAction,
  TextField,
} from "components";

import { useFetchData } from "hooks";

import {
  convertDateArrayToDate,
  getEndDate,
  getStartDate,
  moneyFormat,
  validateNull,
} from "utils";
import { selectedCustomerPropTypes } from "./propTypes";

import CUSTOMER_LEDGER from "./constants";

const rowsPerPage = 20;
const defaultFrom = new Date(new Date().setMonth(new Date().getMonth() - 12));
const defaultTo = new Date();

const defaultTimeIntervalParam = `startTime=${defaultFrom.getTime()}&endTime=${defaultTo.getTime()}`;
const LedgerTable = ({ selectedCustomer }) => {
  const [page, setPage] = useState(0);
  const [params, setParams] = useState(defaultTimeIntervalParam);
  const theme = useTheme();

  const { handleSubmit, getValues, control, watch, setValue, resetField } =
    useForm({
      mode: "onTouched",
    });

  const { refetch: fetchLedgerDetails, data: ledgerData } = useFetchData(
    "ledger-details",
    `/ledger-service/api/internal/v1/fetch-ledger-transaction?offset=${
      page * rowsPerPage
    }&limit=${rowsPerPage}&gstin=${selectedCustomer?.gstin}&${params}`,
  );

  useEffect(() => {
    fetchLedgerDetails();
  }, [selectedCustomer, page, params]);

  /**
   * @description Handle pagination
   */
  const handlePageChange = useCallback(
    (_event, pageNumber) => setPage(pageNumber),
    [],
  );

  const getAmount = (credit, debit) => {
    if (credit > 0) {
      return {
        amount: `${moneyFormat(credit)} (Cr.)`,
        color: theme.palette.success.dark,
      };
    } else {
      return {
        amount: `${moneyFormat(debit)} (Dr.)`,
        color: theme.palette.error.dark,
      };
    }
  };

  const handleFetchLedger = () => {
    setPage(0);
    const startDate = new Date(
      getStartDate(new Date(getValues("fromDate"))),
    ).getTime();

    const endDate = new Date(
      getEndDate(new Date(getValues("toDate"))),
    ).getTime();

    const timeInterval = `startTime=${startDate}&endTime=${endDate}`;

    let searchFilter = "";
    switch (getValues("searchType")) {
      case "Document type":
        searchFilter = `documentTypeFilter=${getValues("searchValue")}`;
        break;
      case "Order Id":
        searchFilter = `orderNumber=${getValues("searchValue")}`;
        break;
      case "Document no.":
        searchFilter = `documentNumber=${getValues("searchValue")}`;
        break;
      default:
        searchFilter = "";
    }
    const filterUrl = `${searchFilter}${
      searchFilter ? "&" : ""
    }${timeInterval}`;
    setParams(filterUrl);
  };

  const isFetching = false;

  const ledgerDetails = ledgerData?.data?.data;

  const resetFilter = () => {
    resetField("searchType");
    resetField("searchValue");
    resetField("fromDate");
    resetField("toDate");

    setPage(0);
    setParams(defaultTimeIntervalParam);
  };

  return (
    <Box mt={4}>
      <Typography fontSize={18} fontWeight={"600"}>
        Ledger
      </Typography>
      {!!ledgerDetails?.isBlackListed && (
        <Box
          display={"flex"}
          alignItems={"center"}
          justifyContent={"center"}
          padding={8}
          sx={{
            backgroundColor: theme.palette.error.errorBgColor,
            color: theme.palette.error.dark,
          }}
        >
          There is some error in the ledger. Do not share this ledger with the
          customer. Reach out to the Product Success team to get the correct
          ledger.
        </Box>
      )}
      <Box
        sx={{
          padding: 2,
          display: "flex",
          flexDirection: "row",
        }}
        as="form"
        onSubmit={handleSubmit(handleFetchLedger)}
      >
        <Controller
          control={control}
          name="searchType"
          // defaultValue={CUSTOMER_LEDGER?.ledgerFilterType[0]}
          render={({ field: { onChange, value } }) => (
            <DropdownV1
              itemList={CUSTOMER_LEDGER?.ledgerFilterType}
              value={value}
              onChange={(e) => {
                setValue("searchValue", "");
                onChange(e);
              }}
              placeholder="Select"
              listType="array"
              sx={{ width: 220 }}
            />
          )}
        />
        {watch("searchType") === "Document type" ? (
          <Controller
            control={control}
            name="searchValue"
            render={({ field: { onChange, value } }) => (
              <DropdownV1
                itemList={
                  ledgerDetails?.transactionFilter?.availableDocumentType
                }
                value={value}
                onChange={onChange}
                placeholder="Select"
                listType="array"
                sx={{ width: 220, marginLeft: 5 }}
              />
            )}
          />
        ) : (
          <Controller
            control={control}
            name="searchValue"
            defaultValue={""}
            render={({ field: { onChange, value } }) => (
              <TextField
                value={value}
                onChange={onChange}
                placeholder="Search here"
                size="small"
                sx={{ width: 220, marginLeft: 5 }}
              />
            )}
          />
        )}
        <Box width={170} ml={4}>
          <Controller
            control={control}
            name="fromDate"
            defaultValue={
              new Date(new Date().setMonth(new Date().getMonth() - 12))
            }
            render={({ field: { onChange, value } }) => (
              <DatePickerV1
                inputProps={{
                  placeholder: "From",
                }}
                disableFuture={true}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Box>
        <Box width={170} ml={4}>
          <Controller
            control={control}
            name="toDate"
            defaultValue={new Date()}
            render={({ field: { onChange, value } }) => (
              <DatePickerV1
                inputProps={{
                  placeholder: "To",
                }}
                disableFuture={true}
                onChange={onChange}
                value={value}
              />
            )}
          />
        </Box>
        <ButtonV1
          title="Show results"
          type="submit"
          style={{ marginTop: 0, marginLeft: 6, marginRight: 4 }}
        />
        <ButtonV1 title={"reset"} variant="outlined" onClick={resetFilter} />
      </Box>
      <Paper>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
            marginTop: 2,
          }}
        >
          <Table stickyHeader>
            <TableHead style={{ borderRadius: "10px 10px 0 0" }}>
              <TableRow>
                {CUSTOMER_LEDGER.ledgerHeader.map((heading) => (
                  <TableCell key={heading}>{heading}</TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {isFetching ? (
                <TableRow>
                  <TableCell colSpan={CUSTOMER_LEDGER?.ledgerHeader?.length}>
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              ) : !!ledgerDetails?.transactionData?.length ? (
                ledgerDetails.transactionData.map((entry, index) => {
                  const amountData = getAmount(
                    entry?.creditAmount,
                    entry?.debitAmount,
                  );

                  return (
                    <>
                      <TableRow key={index}>
                        <TableCell>
                          <Typography variant="body2">
                            {convertDateArrayToDate(entry.documentDate)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(entry.documentType)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(entry.documentId)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(entry.orderNumber)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {validateNull(entry.description)}
                          </Typography>
                        </TableCell>
                        <TableCell>
                          <Typography variant="body2">
                            {convertDateArrayToDate(entry.dueDate)}
                          </Typography>
                        </TableCell>
                        <TableCell variant="body2">
                          <Typography style={{ color: amountData?.color }}>
                            {amountData?.amount}
                          </Typography>
                        </TableCell>
                        <TableCell variant="body2">
                          <Typography>{moneyFormat(entry.balance)}</Typography>
                        </TableCell>
                      </TableRow>
                    </>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={CUSTOMER_LEDGER?.ledgerHeader?.length}>
                    <Typography textAlign={"center"}>No data found</Typography>
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!ledgerDetails?.transactionData?.length && (
          <TablePagination
            rowsPerPageOptions={[rowsPerPage]}
            rowsPerPage={rowsPerPage}
            page={page}
            count={ledgerDetails?.totalCount ?? 0}
            onPageChange={handlePageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Paper>
    </Box>
  );
};

export default LedgerTable;

LedgerTable.propTypes = {
  selectedCustomer: PropTypes.shape(selectedCustomerPropTypes).isRequired,
};

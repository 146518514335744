
import { useState } from "react";
import { Tabs, Tab, Box, Typography } from "@mui/material";

import UploadInvoiceRejection from "./UploadInvoiceRejection";
import InvoiceRejectionApproval from "./InvoiceRejectionApproval";
import theme from "themeProvider";



const InvoiceRejectionTab = {
    upload : "Upload invoice",
    approval : "Invoice rejection approval"
};

const TabPanel = ({ children, value, selectedTab, tabName, ...rest }) => {
  return (
    <Box
      role="tabpanel"
      hidden={value !== selectedTab}
      id={`tabpanel-${value}`}
      aria-labelledby={`tab-${value}`}
      {...rest}
      style={{ cursor: "pointer" }}
    >
      {value === selectedTab && <>{children}</>}
    </Box>
  );
};

const BulkInvoiceRejection = () => {

    const [tabName,selectTab] = useState(InvoiceRejectionTab?.upload);


  return (
    <Box px={8} py={2}>
      <Typography variant="h2">Bulk invoice rejection</Typography>
      <Box sx={{ borderBottom: 1, borderColor: "lightgrey", mb: 4 }}>
        <Tabs
          value={tabName}
          onChange={(_, newTabValue) => {
            selectTab(newTabValue);
          }}
          sx={{
            ">.MuiTabs-scroller": {
              ">.MuiTabs-indicator": {
                backgroundColor: theme.palette.common.black,
              },

              ">.MuiTabs-flexContainer>.Mui-selected": {
                fontWeight: 600,
                color: theme.palette.common.black,
              },
              ">.MuiTabs-flexContainer>.MuiButtonBase-root": {
                paddingLeft: 0,
                paddingRight: 0,
                marginRight: 6,
              },
            },
          }}
          aria-label="Invoice rejection tabs"
        >
          <Tab label={"Upload invoice rejection csv"} value={InvoiceRejectionTab?.upload} />
          <Tab label={"Invoice rejection approval"} value={InvoiceRejectionTab?.approval} />
        </Tabs>
      </Box>
      <TabPanel value={InvoiceRejectionTab?.upload} selectedTab={tabName}>
       <UploadInvoiceRejection/>
      </TabPanel>
      <TabPanel value={InvoiceRejectionTab?.approval}  selectedTab={tabName}>
        <InvoiceRejectionApproval/>
      </TabPanel>
    </Box>
  );
};

export default BulkInvoiceRejection;

import { useEffect, useState, forwardRef, useImperativeHandle } from "react";
import { useSelector } from "react-redux";
import { Controller, useForm } from "react-hook-form";
import { TableCell, TableRow, Typography, Box, Grid } from "@mui/material";
import { ArrowDropDown, ArrowDropUp } from "@mui/icons-material";

import {
  ButtonV1,
  Loader,
  TableWrapper,
  ModalBox,
  StatusChip,
  DropdownV1,
  DateTimePicker,
} from "components";
import { ColumnTitle } from "CustomStyledComponents";
import DispatchOrderRecordDetails from "./DispatchOrderRecordDetails";
import DispatchRecordBreakup from "./DispatchRecordBreakup";
import RequestShortCloseModal from "./RequestShortCloseModal";

import { useFetchData, useToaster } from "hooks";
import {
  getAttributes,
  decimalRoundOff,
  moneyFormat,
  validateNull,
  pascalCase,
} from "utils";
import { getAddress, getLabelType } from "./utils";
import { getCall_v2, postCall_v2, putCall_v2 } from "services";
import { success, error, DELIVERY_TYPES } from "constants";
import { DISPATCH_ORDER } from "./constants";

const {
  dispatchRecordHeader,
  status,
  dipatchOrderDetailsHeader,
  plannedShipmentStatus,
} = DISPATCH_ORDER;

const DispatchOrderRecord = forwardRef(
  (
    {
      fetchLineItem,
      isCreditBlocked,
      resetSelectedSeller,
      multiSellerData = [],
    },
    ref,
  ) => {
    const [isModalVisible, setModalVisibility] = useState(false);
    const [isRequestModalOpen, setRequestModalOpen] = useState(false);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [lineItemData, setLineItemData] = useState();
    const [isCollapseOpen, setCollapseOpen] = useState(false);
    const [doRequestItem, setDoRequestItem] = useState();
    const triggerToaster = useToaster();

    const orderDetails = useSelector((state) => state.orderDetails);
    const { orderNumber, deliveryType } = orderDetails;

    const { control, handleSubmit, setValue, getValues, watch } = useForm({
      mode: "onTouched",
    });

    // Format pickup locations
    const pickUpLocations = [];
    multiSellerData?.length &&
      multiSellerData?.forEach((seller) => {
        pickUpLocations.push(...seller?.sellerAddress);
      });

    const {
      refetch: fetchOrderRecords,
      isFetching: isRecordFetching,
      data: dispatchRecord,
    } = useFetchData(
      "dispatch-order-record",
      `/oms/plant-supply/requested-payments?orderNumber=${orderNumber}`,
    );
    useEffect(() => {
      fetchOrderRecords();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useImperativeHandle(ref, () => ({
      invokeRecordApi: () => fetchOrderRecords(),
      getDispatchRecord: () => dispatchRecord,
    }));

    /**
     * @description toggle modal and set action
     */
    const toggleModal = async (record) => {
      if (!isModalVisible) {
        try {
          const { data } = await getCall_v2(
            `/oms/plant-supply/dispatch-order/details?psId=${record?.psId}`,
          );
          setLineItemData(data);
        } catch (err) {
          triggerToaster(`Error in getting records`, error);
          return;
        }
        setSelectedRecord(record);
      } else {
        setCollapseOpen(false);
        setSelectedRecord(null);
      }
      setModalVisibility(!isModalVisible);
    };
    /**
     * @description Invoke status change api
     */
    const markDispatchRecord = async (selectedStatus) => {
      try {
        let url;
        if (selectedStatus === status.release) {
          url = `oms/plant-supply/status/release?psId=${selectedRecord?.psId}`;
          await postCall_v2(url);
        } else {
          url = `/oms/plant-supply`;
          const { psId, psIdentifier } = selectedRecord;
          const payload = {
            psId,
            psIdentifier,
            transactionStatus:
              status.rejected.charAt(0).toUpperCase() +
              status.rejected.slice(1),
            rejectionReason: `Rejected ${psId}`,
          };
          await putCall_v2(url, payload);
        }
        triggerToaster(`Successfully changed status`, success);
        fetchOrderRecords();
        //invoke dispatch lineitems
        fetchLineItem();
      } catch (ex) {
        console.log(`Error in changing dispatch status, ${ex}`);
        triggerToaster(`Error in changing dispatch order status`, error);
      } finally {
        //close modal & remove set status
        setModalVisibility(!isModalVisible);
        setSelectedRecord(null);
        resetSelectedSeller();
      }
    };

    const handleCollapsible = () => {
      setCollapseOpen((prev) => !prev);
    };

    const getLineItemDispatchedQty = (rowItem) => {
      const dispatchQty = rowItem?.shipmentLinkList.reduce(
        (accumulator, currentValue) =>
          accumulator + currentValue?.shippedQuantity,
        0,
      );
      return dispatchQty;
    };

    const showPendingQtyLabel = (record) => {
      const { psPlannedQuantity, psShippedQuantity } = record ?? {};
      const result = decimalRoundOff(psPlannedQuantity - psShippedQuantity, 3);
      const uom =
        record?.psLineItemsDetails?.[0]?.measurementUnit ===
        DISPATCH_ORDER.uomBag
          ? DISPATCH_ORDER.uomBagLabel
          : record?.psLineItemsDetails?.[0]?.measurementUnit;
      let quantityText =
        psPlannedQuantity > psShippedQuantity ? "Pending" : "PT";
      return `${result}  ${uom}  (${quantityText})`;
    };

    const showPendingQtyLabelInDetailsModel = (pendingQuantity, uom) => {
      const result = Math.abs(pendingQuantity);
      let quantityText = pendingQuantity > 0 ? "Pending" : "PT";
      quantityText = result > 0 ? `(${quantityText})` : "";
      return `${result}  ${uom}  ${quantityText}`;
    };

    const toggleShortCloseModal = () => {
      setRequestModalOpen(!isRequestModalOpen);
    };

    const autoShortCloseRequest = async (item) => {
      const payload = {
        psIdentifier: selectedRecord?.psIdentifier,
        orderNumber,
        lineItemIds: [item?.lineItemId],
      };
      try {
        const res = await postCall_v2(
          `/oms/plant-supply/do/short-close/release`,
          payload,
        );
        triggerToaster(res?.data, success);
      } catch (err) {
        triggerToaster(`${err?.data?.detail}`, error);
      } finally {
        toggleModal();
      }
    };
    const onRequestShortClose = (item, totalDispatchedQty) => {
      if (totalDispatchedQty > item?.plannedQuantity * 0.9) {
        autoShortCloseRequest(item);
      } else {
        setDoRequestItem({
          record: selectedRecord,
          item,
          totalDispatchedQty,
          orderNumber: orderNumber,
        });
        toggleModal();
        toggleShortCloseModal();
      }
    };

    // Seller change logic start here
    useEffect(() => {
      setValue("address", getDefaultAddress());
      setValue("sellerAddress", getDefaultAddress()?.vendorLocationDisplayName);
    }, [lineItemData]);

    const getDefaultAddress = () => {
      const selectedAddress = lineItemData?.sellerAddressMetadata;
      return selectedAddress;
    };

    const getFormattedAddress = () => {
      const addressInput = watch("address");
      if (addressInput) {
        const address = getAddress(addressInput);
        return address;
      }
      return "-";
    };

    const handlePickUpLocationChange = (selectedLocation) => {
      const selectedAddress = lineItemData?.sellerAddresses?.find(
        (item) => item?.vendorLocationDisplayName === selectedLocation,
      );
      setValue("address", selectedAddress);
      const validStateForLocation = getDefaultAddress()?.address?.state;
      if (selectedAddress?.address?.state !== validStateForLocation) {
        setValue("isInValidPickUpLocation", true);
        triggerToaster(
          "The Pick up location selected is form a different state than what is selected in order",
          error,
        );
      } else {
        setValue("isInValidPickUpLocation", false);
      }
    };

    const handleUpdatePickUpLocation = async () => {
      const payload = {
        psId: selectedRecord?.psId,
        pickupLocationMetadata: getValues("address"),
      };
      try {
        const res = await postCall_v2(
          `/oms/plant-supply/do/update-pickup-location`,
          payload,
        );
        if (res?.data) {
          triggerToaster(res?.data, success);
        }
      } catch (err) {
        triggerToaster(`${err?.data?.detail}`, error);
      } finally {
        toggleModal();
      }
    };

    const isJotsOrSellerDeliveryType =
      deliveryType === DELIVERY_TYPES?.jots ||
      deliveryType === DELIVERY_TYPES.sellerDelivery;

    return (
      <>
        <TableWrapper
          tableHeader={dispatchRecordHeader.map((heading, index) => {
            return (
              <TableCell key={index}>
                <ColumnTitle>{heading}</ColumnTitle>
              </TableCell>
            );
          })}
          tableBody={
            isRecordFetching ? (
              <>
                <TableRow>
                  <TableCell colSpan={dispatchRecordHeader.length}>
                    <Loader sx={{ margin: 0 }} />
                  </TableCell>
                </TableRow>
              </>
            ) : (
              <>
                {dispatchRecord?.data?.plantSupplyRequestPayments ? (
                  dispatchRecord?.data?.plantSupplyRequestPayments?.map(
                    (record) => {
                      return (
                        <TableRow>
                          <TableCell>{record?.psIdentifier}</TableCell>
                          <TableCell>
                            {moneyFormat(
                              record?.psPlannedShipmentAmount +
                                +(record?.freightValue || 0),
                            )}
                          </TableCell>
                          <TableCell>
                            {`${record?.psPlannedQuantity} `}
                            {record?.psLineItemsDetails?.[0]
                              ?.measurementUnit === DISPATCH_ORDER.uomBag
                              ? DISPATCH_ORDER.uomBagLabel
                              : record?.psLineItemsDetails?.[0]
                                  ?.measurementUnit}
                          </TableCell>
                          <TableCell>
                            {`${record?.psShippedQuantity ?? 0} `}
                            {record?.psLineItemsDetails?.[0]
                              ?.measurementUnit === DISPATCH_ORDER.uomBag
                              ? DISPATCH_ORDER.uomBagLabel
                              : record?.psLineItemsDetails?.[0]
                                  ?.measurementUnit}
                          </TableCell>
                          <TableCell>{showPendingQtyLabel(record)}</TableCell>
                          <TableCell>
                            <StatusChip
                              label={record.transactionStatus}
                              type={getLabelType(record.transactionStatus)}
                              style={{ minWidth: 75 }}
                            />
                          </TableCell>
                          <TableCell>
                            <ButtonV1
                              title={"Details"}
                              variant="text"
                              style={{ padding: 0 }}
                              boxStyle={{ justifyContent: "left" }}
                              onClick={() => toggleModal(record)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    },
                  )
                ) : (
                  <>
                    <TableCell
                      colSpan={dispatchRecordHeader.length}
                      align="center"
                    >
                      <Typography textAlign={"center"}>
                        No dispatch order records found
                      </Typography>
                    </TableCell>
                  </>
                )}
              </>
            )
          }
        />

        <ModalBox open={isModalVisible} onCloseModal={toggleModal} width="80%">
          <Box
            p={4}
            as="form"
            onSubmit={handleSubmit(() => markDispatchRecord(status.release))}
          >
            <Box display={"flex"} flexDirection={"column"} mb={4}>
              <Box display={"flex"} mb={4}>
                <Typography fontSize={20}>Dispatch order id: </Typography>
                <Typography fontSize={20} fontWeight={600}>
                  {selectedRecord?.psId}
                </Typography>
              </Box>

              <DispatchOrderRecordDetails
                showPendingQtyLabel={showPendingQtyLabel}
                selectedRecord={selectedRecord}
              />
              <Grid container mt={4}>
                {isJotsOrSellerDeliveryType && (
                  <>
                    <Grid xs={2.2}>
                      <Typography fontWeight={600} mb={1}>
                        Pick-up slot: From
                      </Typography>
                      <Controller
                        control={control}
                        name={`pickUpFrom${selectedRecord?.psId}`}
                        defaultValue={selectedRecord?.pickUpSlotFrom + "Z"}
                        render={({ field: { value } }) => (
                          <DateTimePicker
                            dateStyle={{ width: "80%" }}
                            value={value}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={2.2}>
                      <Typography fontWeight={600} mb={1}>
                        Pick-up slot: To
                      </Typography>
                      <Controller
                        control={control}
                        name={`pickUpTo${selectedRecord?.psId}`}
                        defaultValue={selectedRecord?.pickUpSlotTo + "Z"}
                        render={({ field: { value, onChange } }) => (
                          <DateTimePicker
                            dateStyle={{ width: "80%" }}
                            value={value}
                            disabled={true}
                          />
                        )}
                      />
                    </Grid>
                  </>
                )}
                <Grid xs={2.2}>
                  <Typography fontWeight={600} mb={1}>
                    Seller name
                  </Typography>
                  <Typography>
                    {validateNull(selectedRecord?.sellerCompanyName)}
                  </Typography>
                </Grid>
                {isJotsOrSellerDeliveryType && (
                  <>
                    <Grid xs={2.2}>
                      <Typography fontWeight={600} mb={1}>
                        Pickup location
                      </Typography>
                      <Controller
                        control={control}
                        name={"sellerAddress"}
                        render={({ field: { value, onChange } }) => (
                          <DropdownV1
                            sx={{
                              width: "80%",
                            }}
                            disabled={
                              selectedRecord?.transactionStatus !== "Release"
                            }
                            itemList={lineItemData?.sellerAddresses}
                            displayKey={"vendorLocationDisplayName"}
                            valueKey={"vendorLocationDisplayName"}
                            value={pascalCase(value ?? "")}
                            onChange={(e) => {
                              handlePickUpLocationChange(e?.target?.value);
                              onChange(e);
                            }}
                          />
                        )}
                      />
                    </Grid>
                    <Grid xs={2.2}>
                      <Typography fontWeight={600} mb={1}>
                        Address
                      </Typography>
                      <Typography>{getFormattedAddress()}</Typography>
                    </Grid>
                  </>
                )}
              </Grid>
            </Box>

            <Box mb={6}>
              <TableWrapper
                tableHeader={
                  <>
                    {dipatchOrderDetailsHeader.map((heading, index) => {
                      return (
                        <TableCell key={index}>
                          <ColumnTitle>{heading}</ColumnTitle>
                        </TableCell>
                      );
                    })}
                    <TableCell></TableCell>
                  </>
                }
                tableBody={
                  lineItemData &&
                  lineItemData?.plannedShipmentLineItemDetails?.map((item) => {
                    const totalDispatchedQty = getLineItemDispatchedQty(item);
                    const measurementUnit =
                      item.measurementUnit === DISPATCH_ORDER.uomBag
                        ? DISPATCH_ORDER.uomBagLabel
                        : item.measurementUnit;
                    return (
                      <>
                        <TableRow as={Box} onClick={handleCollapsible}>
                          <TableCell style={{ width: 200 }}>
                            {item?.productInfo?.name}
                            {getAttributes(item?.productInfo?.attributes)}
                          </TableCell>
                          <TableCell>
                            {`${item?.plannedQuantity} `}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>
                            {`${totalDispatchedQty} `}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>
                            {decimalRoundOff(
                              (item?.plannedQuantity ?? 0) -
                                (totalDispatchedQty ?? 0),
                            )}
                            {measurementUnit}
                          </TableCell>
                          <TableCell>{moneyFormat(item?.price)}</TableCell>
                          <TableCell>
                            <ButtonV1
                              title={"Request for DO short closure"}
                              variant="text"
                              size="small"
                              disabled={
                                selectedRecord?.transactionStatus !==
                                plannedShipmentStatus?.Release
                              }
                              onClick={() =>
                                onRequestShortClose(item, totalDispatchedQty)
                              }
                            />
                          </TableCell>

                          <TableCell>
                            {isCollapseOpen ? (
                              <ArrowDropUp />
                            ) : (
                              <ArrowDropDown />
                            )}
                          </TableCell>
                        </TableRow>
                        <TableRow sx={{ padding: 0 }}>
                          <TableCell
                            sx={{ padding: isCollapseOpen ? 3 : 0 }}
                            colSpan={6}
                          >
                            <DispatchRecordBreakup
                              isCollapseOpen={isCollapseOpen}
                              item={item}
                              showPendingQtyLabelInDetailsModel={
                                showPendingQtyLabelInDetailsModel
                              }
                              measurementUnit={measurementUnit}
                            />
                          </TableCell>
                        </TableRow>
                      </>
                    );
                  })
                }
              />
            </Box>

            {selectedRecord?.transactionStatus !== "Release" ? (
              <Box display={"flex"} gap={4} justifyContent={"flex-end"}>
                <ButtonV1
                  title={"Release"}
                  size="small"
                  type="submit"
                  disabled={
                    [status.release, status.rejected].includes(
                      selectedRecord?.transactionStatus?.toLowerCase(),
                    ) ||
                    isCreditBlocked ||
                    orderDetails?.customerFreezed ||
                    orderDetails?.isCreditBlocked
                  }
                />
                <ButtonV1
                  variant="outlined"
                  title={"Reject"}
                  size="small"
                  disabled={[status.release, status.rejected].includes(
                    selectedRecord?.transactionStatus?.toLowerCase(),
                  )}
                  onClick={() => markDispatchRecord(status.cancel)}
                />
              </Box>
            ) : (
              <Box display={"flex"} justifyContent={"flex-end"}>
                <ButtonV1
                  title={"Update"}
                  size="medium"
                  disabled={!lineItemData?.sellerAddressMetadata}
                  onClick={handleUpdatePickUpLocation}
                />
              </Box>
            )}
          </Box>
        </ModalBox>
        <RequestShortCloseModal
          data={doRequestItem}
          open={isRequestModalOpen}
          toggleModal={toggleShortCloseModal}
        />
      </>
    );
  },
);

export default DispatchOrderRecord;

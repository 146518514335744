import { DISPATCH_ORDER } from "../constants";
import { STATUS_CHIP } from "constants";

export const requestPaymentCalculation = (data, getValues) => {
  let price = 0;
  let uomQty = {};
  data?.forEach(({ lineItemId, ratePerUnit, uom }) => {
    const values = getValues(
      `${DISPATCH_ORDER.yupConfig.requestPayment}.${lineItemId}`,
    );
    const isChecked = values?.[DISPATCH_ORDER.yupConfig.checkedBox];

    uomQty = {
      ...uomQty,
      [uom]:
        (uomQty[uom] || 0) +
        +(isChecked ? values?.[DISPATCH_ORDER.yupConfig.plannedQty] : 0),
    };
    price +=
      +(isChecked ? values?.[DISPATCH_ORDER.yupConfig.plannedQty] : 0) *
      ratePerUnit;
  });

  return { price, uomQty };
};

export const getUomString = (uomQty) =>
  uomQty
    ? Object.keys(uomQty).map((uom, index) => {
        const length = Object.keys(uomQty).length;
        const connection =
          index < length - 2 ? ", " : index < length - 1 ? " and " : "";
        const qty = new Intl.NumberFormat("en-IN").format(uomQty[uom]);
        return `${qty} ${uom}${connection}`;
      }, "")
    : "";

export const getLabelType = (status) => {
  const {
    Planned,
    Paid,
    Partial,
    Release,
    Created,
    new: newState,
  } = DISPATCH_ORDER.plannedShipmentStatus;
  switch (status) {
    case Planned:
    case Partial:
    case newState:
    case Created:
      return STATUS_CHIP.warning;
    case Paid:
    case Release:
      return STATUS_CHIP.success;
    default:
      return STATUS_CHIP.error;
  }
};

const getCommaConcatenatedValue = (value) => {
  return value ? `${value},` : "";
};

// Get valid address
export const getAddress = (selectedAddress) => {
  const {
    address: { address_line_1, address_line_2, city, pincode, state },
  } = selectedAddress;
  const formattedAddress =
    getCommaConcatenatedValue(address_line_1) +
    getCommaConcatenatedValue(address_line_2) +
    getCommaConcatenatedValue(pincode) +
    getCommaConcatenatedValue(city) +
    getCommaConcatenatedValue(state);

  return formattedAddress;
};

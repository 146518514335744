import { Box, Typography } from "@mui/material";
import PropTypes from "prop-types";
import { ViewOnly as CommonViewOnly } from "components";

const ViewOnly = ({ label, value }) => {
  return (
    <CommonViewOnly
      rest={{
        flexDirection: "column",
      }}
      labelStyle={{
        fontWeight: "400",
        fontSize: 14,
      }}
      valueStyle={{
        fontWeight: "600",
        fontSize: 16,
        marginTop: 4,
      }}
      label={label}
      value={value}
    />
  );
};

export default ViewOnly;

ViewOnly.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
};

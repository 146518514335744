import React from "react";
import Chip from "@mui/material/Chip";
import { useTheme } from "@mui/material";

import { STATUS_CHIP } from "constants";

const StatusChip = ({ type, label, style = {}, ...rest }) => {
  const theme = useTheme();
  const internalStyle = {
    [STATUS_CHIP?.success]: {
      sx: {
        color: theme.palette.success?.dark,
        background: theme.palette.success?.main,
      },
    },
    [STATUS_CHIP?.warning]: {
      sx: {
        color: theme.palette.warning?.dark,
        background: theme.palette.warning?.main,
      },
    },
    [STATUS_CHIP?.error]: {
      sx: {
        color: theme.palette.error?.dark,
        background: theme.palette.error?.main,
      },
    },
  };

  return (
    <Chip
      label={label}
      sx={{ ...internalStyle[type]?.sx, ...style }}
      size="small"
      {...rest}
    />
  );
};

export default React.memo(StatusChip);

import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import {
  Box,
  FormControl,
  FormControlLabel,
  Radio,
  RadioGroup,
  Typography,
} from "@mui/material";
import { FormProvider, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

import LineItemList from "./LineItemList";
import OrderSummary from "./OrderSummary";
import { FloatingBar, ButtonV1, ModalBox, DropdownV1 } from "components";
import PayoutCreationModal from "./PayoutCreationModal";
import CreditOrderRejectModal from "./components/CreditOrderRejectModal";

import { postCall_v2, putCall_v2 } from "services";
import { useCategoryTypeCheck, useFetchData, useToaster } from "hooks";

import { DetailsTab as detailsTab } from "./constants";
import { success, error } from "constants";
import { reasonTypes } from "constants";
const {
  errorMsgs: { ERROR_MSG },
  CREDIT_APPROVER_MODULE,
  CREATED,
  CREDIT,
  CANCELLED,
  DECLINED,
  PLACED,
  CONFIRMED,
  PAYOUT_CREATION_CONFIRM_MSG,
  PAYOUT_CREATION_MSG,
  JODL_ORDER_STATUS,
} = detailsTab;

const isApprove = false;

const DetailsTab = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const lineItemRef = useRef();
  const orderRef = useRef();
  const category = useCategoryTypeCheck();

  const [orderDetailsOnMount, setOrderDetailsOnMount] = useState(false);
  const [isInValidCashback, setIsInValidCashback] = useState(false);
  const orderDetails = useSelector((state) => state.orderDetails);
  const userData = useSelector((state) => state.userData);
  const [rejectText, setRejectMsg] = useState("");
  const [rejectModal, showRejectionModel] = useState(false);
  const [isPayoutModal, setIsPayoutModal] = useState(false);
  const [isPayoutApplicable, setIsPayoutApplicable] = useState(false);
  const [isCreditRejectModalOpen, setIsCreditRejectModalOpen] = useState(false);
  const triggerToaster = useToaster();

  const methods = useForm({
    mode: "onTouched",
  });

  const { handleSubmit, reset, getValues } = methods;

  // Fetch reasons for reject reason list
  const { refetch: fetchReasons, data: reasonList } = useFetchData(
    "fetch-reject-reason-list",
    `oms/rejection/get-reasons-by-type?type=${reasonTypes?.ORDER_REJECTION}`,
  );

  const updateOrderDetails = async (isPayoutApplicable = false) => {
    /*
     * @description - New validation from FE side. Required reason for cancellation or decline required
     * when order status is declined or cancelled
     */
    const { orderStatus, reasonForCancellation, reasonForDecline } =
      getValues();
    if (
      (orderStatus === "Cancelled" && !reasonForCancellation) ||
      (orderStatus === "Declined" && !reasonForDecline)
    ) {
      const errorMsg =
        orderStatus === "Cancelled"
          ? "Reason for cancellation / rejection  is required"
          : "Reason for decline is required";
      triggerToaster(errorMsg, error);
      return;
    }
    // Fetch update order details and lineItem
    await orderRef?.current?.onOrderUpdate(isPayoutApplicable);
    await lineItemRef?.current?.fetchUpdatedLineItems();
  };

  const isOrderDetailsonMount = () => setOrderDetailsOnMount(true);

  const handleOrderUpdate = () => {
    if ([CANCELLED, DECLINED].includes(getValues("orderStatus"))) {
      setIsPayoutModal(true);
    } else {
      updateOrderDetails();
    }
  };

  const navigateToOrderList = useCallback(
    () => navigate(`/order-list/${id}?tab=orderDetails`),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [navigate],
  );
  const handleApproveReject = async (approved, reason = "") => {
    try {
      const { orderNumber } = orderDetails;
      const data = orderRef.current.getCreditData();

      const body = {
        orderNumber,
        creditAmount: data?.creditAmount,
        approvedBy: userData.email,
        remark: `${approved ? "Approving credit order" : reason}`,
        approved,
        id: data?.id,
        pan: data?.pan,
      };

      const res = await postCall_v2("oms/credit/approve", body);
      if (res.status === 200) {
        const successMsg = `Order ${approved ? "approved" : "rejected"} `;
        reset();
        triggerToaster(successMsg, success);
        navigateToOrderList();
        orderRef.current.fetchOrderDetails();
      } else {
        triggerToaster(ERROR_MSG, error);
      }
    } catch (err) {
      const { response } = err ?? {};
      triggerToaster(
        response?.data?.detail ? response?.data?.detail : ERROR_MSG,
        error,
      );
    }
  };

  const handleConfirmation = async () => {
    try {
      const updatedValue = orderRef.current.fetchUpdatedValues();
      //check PI/PO
      if (!updatedValue.isVerifiedPO && !category?.isPrivateBrand) {
        return triggerToaster("Please check PI/PO to confirm order", error);
      }
      if (!updatedValue.opsSpoc) {
        return triggerToaster("Please add Ops SPOC to confirm order", error);
      }

      const { orderNumber, childOrders } = orderDetails;
      const childOrdersStatus = childOrders.map((ele) => {
        const res = {
          sellerOrderNumber: ele.sellerOrderNumber,
          updatedStatus: CONFIRMED,
        };
        return res;
      });
      const reqBody = {
        committedDeliverDate: updatedValue?.committedDeliveryDate,
        orderNumber,
        orderStatus: CONFIRMED,
        opsSpoc: updatedValue?.opsSpoc,
        childOrders: childOrdersStatus,
        isVerifiedPO: !!updatedValue.isVerifiedPO,
        isVerifiedSO: !!updatedValue.isVerifiedSO,
      };

      await putCall_v2("/joms/api/external/v1/order/order-update", reqBody);
      triggerToaster("Order confirmed", success);

      //get updated details
      reset();

      orderRef.current.fetchOrderDetails();
    } catch (err) {
      triggerToaster(err?.data?.errorMessage, error);
      console.error(`Error in approving order : ${err}`);
    }
  };

  const rejectOrder = async () => {
    try {
      const { orderNumber, childOrders } = orderDetails;
      const childOrdersStatus = childOrders.map((ele) => {
        const res = {
          sellerOrderNumber: ele.sellerOrderNumber,
          updatedStatus: CANCELLED,
        };
        return res;
      });
      const reqBody = {
        orderNumber,
        orderStatus: CANCELLED,
        childOrders: childOrdersStatus,
        reasonForCancellation: rejectText,
        isPayoutApplicable,
      };
      await putCall_v2("/joms/api/external/v1/order/order-update", reqBody);
      triggerToaster("Order rejected", success);
      //close modal
      toggleRejectionModal();
      orderRef.current.fetchOrderDetails();
    } catch (err) {
      triggerToaster(`Error in rejecting order`, error);
      console.error(`Error in rejecting order : ${err}`);
    }
  };

  const toggleRejectionModal = () => {
    setRejectMsg("");
    if (!rejectModal) {
      fetchReasons();
    }
    showRejectionModel(!rejectModal);
  };

  const handlePayoutConfirmation = (isPayoutApplicable) => {
    updateOrderDetails(isPayoutApplicable);
    setIsPayoutModal(false);
  };

  const { paymentType, orderStatus, jodlOrderStatus } = orderDetails;

  let isApproveCTA = paymentType === CREDIT && orderStatus === CREATED;
  const { modules } = userData;
  const isApproverOpsUser =
    modules?.length &&
    modules.some((module) => module.name === CREDIT_APPROVER_MODULE);

  const FloatingCtaView = () => {
    if (isApproverOpsUser && isApproveCTA) {
      return (
        <FloatingBar width="70%" bottom={10}>
          <Box mr={4}>
            <ButtonV1
              title="Approve"
              onClick={() => handleApproveReject(!isApprove)}
            />
          </Box>
          <ButtonV1
            style={{ marginRight: 10 }}
            title="Reject"
            variant="outlined"
            onClick={toggleCreditRejectModalOpen}
          />
        </FloatingBar>
      );
    } else if (
      [CREATED, PLACED].includes(orderStatus) ||
      jodlOrderStatus === JODL_ORDER_STATUS.failed
    ) {
      return (
        <FloatingBar width="70%" bottom={10}>
          <Box mr={4}>
            <ButtonV1 title="Confirm order" onClick={handleConfirmation} />
          </Box>
          <ButtonV1
            style={{ marginRight: 10 }}
            title="Reject"
            variant="outlined"
            onClick={toggleRejectionModal}
          />
        </FloatingBar>
      );
    } else if (paymentType === CREDIT) {
      if (orderStatus !== CANCELLED && orderStatus !== DECLINED) {
        return (
          <FloatingBar width="70%" bottom={10}>
            <ButtonV1
              type="submit"
              title="Update"
              disabled={isInValidCashback}
            />
          </FloatingBar>
        );
      }
    } else {
      return (
        <FloatingBar width="70%" bottom={10}>
          <ButtonV1 type="submit" title="Update" disabled={isInValidCashback} />
        </FloatingBar>
      );
    }
  };

  const handleRadioButton = ({ target: { value } }) => {
    setIsPayoutApplicable(value);
  };

  const handleFetchOrderDetails = () => {
    orderRef.current.resetField("orderStatus");
    orderRef.current.fetchOrderDetails();
  };

  const toggleCreditRejectModalOpen = () => {
    setIsCreditRejectModalOpen(!isCreditRejectModalOpen);
    if (!isCreditRejectModalOpen) {
      fetchReasons();
    }
  };

  const handleRejectCredit = (reason) => {
    handleApproveReject(isApprove, reason);
  };

  return (
    <>
      <FormProvider {...methods}>
        <Box as="form" onSubmit={handleSubmit(handleOrderUpdate)}>
          <OrderSummary
            id={id}
            ref={orderRef}
            isOrderDetailsonMount={isOrderDetailsonMount}
            setIsInValidCashback={setIsInValidCashback}
          />
          {FloatingCtaView()}
        </Box>
      </FormProvider>
      <PayoutCreationModal
        open={isPayoutModal}
        onCloseModal={() => setIsPayoutModal(false)}
        orderStatus={getValues("orderStatus")}
        onConfirm={handlePayoutConfirmation}
      />
      <>
        {!!orderDetailsOnMount && (
          <>
            <Box mb={2}>
              <LineItemList
                id={id}
                ref={lineItemRef}
                handleFetchOrderDetails={handleFetchOrderDetails}
              />
            </Box>
            <CreditOrderRejectModal
              isCreditRejectModalOpen={isCreditRejectModalOpen}
              toggleCreditRejectModalOpen={toggleCreditRejectModalOpen}
              onRejectCredit={handleRejectCredit}
              reasons={reasonList?.data}
            />
            {rejectModal && (
              <ModalBox
                width="50%"
                open={rejectModal}
                onCloseModal={toggleRejectionModal}
                avoidBackDropClick={true}
              >
                <Box
                  display={"flex"}
                  justifyContent={"center"}
                  flexDirection={"column"}
                  p={8}
                  gap={4}
                  alignItems={"center"}
                >
                  <Box width={"80%"}>
                    <DropdownV1
                      sx={{ marginBottom: 4 }}
                      onChange={(evt) => setRejectMsg(evt?.target?.value)}
                      itemList={reasonList?.data}
                      valueKey={"reason"}
                      displayKey={"reason"}
                      value={rejectText}
                      placeholder={"Please specify order rejection reason"}
                    />
                    <FormControl>
                      <Typography>{PAYOUT_CREATION_MSG}</Typography>
                      <Typography>{PAYOUT_CREATION_CONFIRM_MSG}</Typography>
                      <RadioGroup
                        onChange={handleRadioButton}
                        value={isPayoutApplicable}
                        row
                      >
                        <FormControlLabel
                          value={true}
                          control={<Radio size="small" />}
                          label="Yes"
                          sx={{ fontSize: 14 }}
                        />
                        <FormControlLabel
                          value={false}
                          control={<Radio size="small" />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Box>
                  <ButtonV1
                    title="Reject"
                    disabled={!rejectText}
                    onClick={rejectOrder}
                  />
                </Box>
              </ModalBox>
            )}
          </>
        )}
      </>
    </>
  );
};

export default DetailsTab;


import { useState, useMemo, useEffect} from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    Typography,
    Divider
  } from "@mui/material";
  import {
    HyperLink,
    PaginationAction,
    ButtonV1,
    Loader,
    TextField,
  } from "components";
  import { StyledTableRow } from "CustomStyledComponents";
import {useFetchData,useToaster} from 'hooks';
import { validateNull } from "utils";
import { getCall_v2, putCall_v2 } from 'services';
import { error } from 'constants';

const ROWS_PER_PAGE = 50;

const InvoiceRejectionApproval = ()=>{
    const [page, setPageCount] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceList,setInvoiceList] = useState([]);

    const triggerToaster = useToaster();


    const onPageChange = (_, pageNumber) => {
        setPageCount(pageNumber);
      };

    const updateApprovalActionOnInvoice = async(id,status)=>{
       const payload = {
        id,
        approvalStatus:status
       }
        const res = await putCall_v2(`/joms/api/external/v1/shipment/update-inv-rejection-approval-status`,payload);
        if(res?.isSuccess){
          fetchInvRejectionWithPendingApproval();
        }else{
            triggerToaster(res?.error?.message??"Please try again", error);
        }
    };


    const updateInvoiceNumber = (evt) => {
        if (evt?.target?.value) {
          setInvoiceNumber(evt.target.value?.trim());
        }
      };

      const fetchInvoiceDetailsForInvoice = async(invoiceNumber)=>{
        const res = await getCall_v2(`/joms/api/external/v1/shipment/fetch-inv-rejection-status?invoiceNumber=${invoiceNumber}`);
        const {data} = res;
        if(data?.isSuccess){
          setInvoiceList(data);
        }
      };
      

      const { refetch: fetchInvRejectionWithPendingApproval, isFetching } = useFetchData(
        "fetch-inv-rej-view",
        `/joms/api/external/v1/shipment/inv-rejection-pending-action-view?page=${
          page + 1
        }&offset=${ROWS_PER_PAGE}&approvalStatus=PENDING`,
        (response)=>{
          const {data} = response;
          if (data?.isSuccess) {
            setInvoiceList(data);
          }
        },
      );

      useEffect(() => {
        fetchInvRejectionWithPendingApproval();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
      }, [page]);



    const tableFields = useMemo(() => {
        return [
            {
                title: "Order number ",
                field: "customerOrderNumber",
                render: (ele) => <HyperLink title={ele?.customerOrderNumber} />,
              },
          {
            title: "Invoice number ",
            field: "invoiceNumber",
            render: (ele) => <Typography style={{ width: 120 }} fontSize={14}>{ele?.invoiceNumber} </Typography>
          },
          {
            title: "Shipment number",
            field: "shipmentNumber",
            render: (ele) => <Typography fontSize={14}>{ele?.shipmentNumber}</Typography>
            ,
          },
          {
            title: "Shipment state",
            field: "shipmentState",
            render: (ele) => {
              var shipmentState = ele?.shipmentState?JSON.parse(ele?.shipmentState):null;
              return (
                <Typography fontSize={14}>{validateNull(shipmentState?.state)}</Typography>
              )
            },
          },
          {
            title: "Rejection reason",
            field: "rejectionReason",
            render: (ele) => 
               (
                <Typography fontSize={14}>{validateNull(ele?.rejectionReason)}</Typography>
              ),
          },{
            title: "Action",
            render: (ele) => 
               (
                <Box
                              display={"flex"}
                              justifyContent={"start"}
                              gap={2}
                            >
                              <ButtonV1
                                title={"Approve"}
                                size="small"
                                onClick={() => updateApprovalActionOnInvoice(ele?.id,"APPROVED") }
                              />
                              <Divider flexItem orientation="vertical" />
                              <ButtonV1
                                title={"Reject"}
                                size="small"
                                variant="outlined"
                                onClick={() => updateApprovalActionOnInvoice(ele?.invoiceNumber,"REJECTED")}
                              />
                            </Box>
              ),
          }

        ];
      }, []);



    return (
        <Box>
 <Box display={"flex"} gap={4} mb={6}>
          <TextField
            value={invoiceNumber}
            onChange={updateInvoiceNumber}
            size={"small"}
            placeholder="Query by invoice no."
          />
          <ButtonV1
            title={"Submit"}
            color="primary"
            variant="contained"
            disabled={!invoiceNumber }
            onClick={()=>fetchInvoiceDetailsForInvoice(invoiceNumber)}
          />
          <ButtonV1
            title="Reset"
            variant="outlined"
            disabled={!invoiceNumber}
            onClick={ ()=> setInvoiceNumber("")}
          />
        </Box>

        <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isFetching || !invoiceList?.invoiceRejectionDetailsViewList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isFetching ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No invoice rejection details found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                invoiceList?.invoiceRejectionDetailsViewList.map((row) => (
                  <StyledTableRow
                    key={row?.ordNumber}
                  >
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!invoiceList?.invoiceRejectionDetailsViewList?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={invoiceList?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>

        </Box>
    )};





export default InvoiceRejectionApproval;
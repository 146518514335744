import { CircularProgress, Box } from "@mui/material";

const Loader = ({ sx = {}, size = 40 }) => {
  return (
    <Box
      style={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        marginTop: 80,
        height: "100%",
        ...sx,
      }}
    >
      <CircularProgress size={size} data-testid="loader" />
    </Box>
  );
};

export default Loader;

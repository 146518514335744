import { Box, TableCell, Typography, useTheme } from "@mui/material";
import PropTypes from "prop-types";

const ErrorView = ({ errors = [], warning = "", colSpan = 10 }) => {
  const theme = useTheme();

  return (
    (!!errors?.length || !!warning) && (
      <TableCell
        colSpan={colSpan}
        style={{
          padding: 0,
          backgroundColor: theme.palette.error.errorBgColor,
          width: "100%",
          color: theme.palette.error.light,
        }}
      >
        <Box
          style={{
            display: "block",
            overflowX: "auto",
            whiteSpace: "nowrap",
            maxWidth: "85vw",
          }}
        >
          <Box
            style={{
              display: "inline-flex",
              alignItems: "center",
            }}
          >
            {errors?.map((error, i) => {
              return (
                <Box
                  style={{
                    backgroundColor: error?.type === "warning" && "yellow",
                    padding: 6,
                    paddingLeft: 2,
                    paddingRight: 4,
                    marginLeft: 8,
                  }}
                >
                  <Typography variant="body2" key={error}>
                    {`${error}`}
                    {errors?.length - 1 !== i && (
                      <span>&nbsp;&nbsp;&nbsp;|</span>
                    )}
                  </Typography>
                </Box>
              );
            })}
            {!!warning && !!errors.length && <span>&nbsp;&nbsp;&nbsp;|</span>}
            {!!warning && (
              <Box
                style={{
                  backgroundColor: "yellow",
                  padding: 6,
                  paddingLeft: 2,
                  paddingRight: 4,
                  marginLeft: 8,
                }}
              >
                <Typography variant="body2">{`${warning}`}</Typography>
              </Box>
            )}
          </Box>
        </Box>
      </TableCell>
    )
  );
};

export default ErrorView;

ErrorView.propTypes = {
  errors: PropTypes.array,
  colSpan: PropTypes.number,
};

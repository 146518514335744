import { DISPATCH_ORDER } from "./constants";

/**
 * @description function for formatting the lineItem data for dispatch order
 */
export const getLineItemData = (data) => {
  const totalQuantity = +data?.productInfo?.primaryQuantity.split(" ")[0];
  let formattedData = {
    uom:
      data?.measurementUnit === DISPATCH_ORDER.uomBag
        ? DISPATCH_ORDER.uomBagLabel
        : data?.measurementUnit,
    skuId: data?.productInfo?.sku || "-",
    skuName: data?.productInfo?.name || "-",
    lineItemId: data?.lineItemId,
    totalQuantity: totalQuantity ?? 0,
    // totalQuantity: +data?.productInfo?.primaryQuantity.split(" ")
    //   .replace(data?.measurementUnitDescription, "")
    //   .trim(),
    plannedQuantity: data?.plannedQuantity || 0,
    totalInvoicedQuantity: data?.totalInvoicedQuantity || 0,
    releasedQuantity: data?.releasedQuantity || 0,
    ratePerUnit: data?.lineItemPrice?.centAmount / 100 || 0,
    attributes: data?.productInfo?.attributes,
    totalShortClosedQuantity: data?.totalShortClosedQuantity || 0,
  };
  return formattedData;
};

export const paymentRequestPayload = (
  data,
  orderId,
  // advanceUtilization = 0,
  estimatedPrice,
  currentLedgerBalance,
  formValues,
  freightValue,
  doCreationData,
  selectedSeller,
) => {
  const psLineItemsDetails = [];
  const requestAmount = estimatedPrice;
  data?.forEach((item, i) => {
    const { lineItemId, uom, totalQuantity } = item;
    const plannedQuantity = formValues.dispatchOrderList[i].plannedQty;
    const isChecked = formValues.dispatchOrderList[i].checkbox;
    if (plannedQuantity && isChecked) {
      psLineItemsDetails.push({
        lineItemId,
        plannedQuantity,
        measurementUnit:
          uom === DISPATCH_ORDER.uomBagLabel ? DISPATCH_ORDER.uomBag : uom,
        totalLineItemQuantity: totalQuantity,
      });
    }
  });
  return {
    customerOrderId: orderId,
    requestAmount: requestAmount > 0 ? requestAmount : 0,
    transactionStatus: "Created",
    psLineItemsDetails,
    currentLedgerBalance,
    freightValue: Number(freightValue),
    sellerCompanyName: selectedSeller,
    ...doCreationData,
  };
};

import { Box, Divider } from "@mui/material";

import { GridSkeleton } from "CustomStyledComponents";
import { ViewOnly } from "./components";

import { validateNull } from "utils";

const ShipmentDetails = ({ shipmentDetails = {} }) => {
  return (
    <Box>
      <GridSkeleton
        firstChild={
          <ViewOnly
            label={"Seller Order Number"}
            value={validateNull(shipmentDetails?.sellerOrderNumber)}
          />
        }
        secondChild={
          <ViewOnly
            label={"Buyer Name"}
            value={validateNull(shipmentDetails?.sapCustomerName)}
          />
        }
      />
      <Divider sx={{ marginTop: 3, marginBottom: 1 }} />
      <GridSkeleton
        firstChild={
          <ViewOnly
            label={"Mode of shipment"}
            value={validateNull(shipmentDetails?.sapModeOfShipment)}
          />
        }
        secondChild={
          <ViewOnly
            label={"Delivery type"}
            value={validateNull(shipmentDetails?.sapDeliveryType)}
          />
        }
      />
    </Box>
  );
};

export default ShipmentDetails;


import { useState, useMemo, useEffect, forwardRef,useImperativeHandle} from 'react';
import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TablePagination,
    Typography,
  } from "@mui/material";
  import {
    HyperLink,
    PaginationAction,
    ButtonV1,
    Loader,
    TextField,
  } from "components";
  import { StyledTableRow } from "CustomStyledComponents";
import {useFetchData} from 'hooks';
import { validateNull,dateConvertor,fetchTimeWithoutConversion,sentenceCase } from "utils";
import { getCall_v2 } from 'services';

const ROWS_PER_PAGE = 25;

const InvoiceRejectionListView = forwardRef((_,ref)=>{
    const [page, setPageCount] = useState(0);
    const [invoiceNumber, setInvoiceNumber] = useState("");
    const [invoiceList,setInvoiceList] = useState([]);


    const onPageChange = (_, pageNumber) => {
        setPageCount(pageNumber);
      };

    const updateInvoiceNumber = (evt) => {
        if (evt?.target?.value) {
          setInvoiceNumber(evt.target.value?.trim());
        }
      };

      const onFilterReset = ()=>{
        setInvoiceNumber("");
        fetchInvRejectionHistory();
      };

      const fetchInvoiceDetailsForInvoice = async(invoiceNumber)=>{
        const res = await getCall_v2(`/joms/api/external/v1/shipment/fetch-inv-rejection-status?invoiceNumber=${invoiceNumber}`);
        const {data} = res;
        if(data?.isSuccess){
          setInvoiceList(data);
        }
      };
      

      const { refetch: fetchInvRejectionHistory, isFetching } = useFetchData(
        "fetch-inv-rej-view",
        `/joms/api/external/v1/shipment/inv-rejection-view?page=${
          page + 1
        }&offset=${ROWS_PER_PAGE}`,
        (response)=>{
          const {data} = response;
          if (data?.isSuccess) {
            setInvoiceList(data);
          }
        },
      );

      useEffect(() => {
        fetchInvRejectionHistory();
        //  eslint-disable-next-line react-hooks/exhaustive-deps
      }, [page]);

      

    // hook to expose function - which will return file and reset file
    useImperativeHandle(ref, () => ({
      fetchInvRejectionHistory: () => fetchInvRejectionHistory(),
    }));

    const tableFields = useMemo(() => {
        return [
            {
                title: "Order number ",
                field: "customerOrderNumber",
                render: (ele) => <HyperLink title={ele?.customerOrderNumber} />,
              },
          {
            title: "Invoice number ",
            field: "invoiceNumber",
            render: (ele) => <Typography style={{ width: 120 }} fontSize={14}>{ele?.invoiceNumber} </Typography>
          },
          {
            title: "Updated invoice number",
            field: "updatedInvoiceNumber",
            render: (ele) => <Typography style={{ width: 120 }} fontSize={14}>{validateNull(ele?.updatedInvoiceNumber)}</Typography>
            ,
          },
          {
            title: "Shipment number",
            field: "shipmentNumber",
            render: (ele) => <Typography fontSize={14}>{ele?.shipmentNumber}</Typography>
            ,
          },
          {
            title: "Shipment state",
            field: "shipmentState",
            render: (ele) => {
              var shipmentState = ele?.shipmentState?JSON.parse(ele?.shipmentState):null;
              return (
                <Typography fontSize={14}>{validateNull(shipmentState?.state)}</Typography>
              )
            },
          },
          {
            title: "Status",
            field: "invoiceRejectionStatus",
            render: (ele) => <Typography fontSize={14}>{sentenceCase(ele?.invoiceRejectionStatus)}</Typography>            ,
          },
          {
            title: "Approval status",
            field: "approvalStatus",
            render: (ele) => <Typography fontSize={14}>{sentenceCase(ele?.approvalStatus)}</Typography>            ,
          },
          {
            title: "Last updated at",
            field: "whenUpdated",
            render: (ele) => <Typography fontSize={14}>{dateConvertor(ele?.whenUpdated)}  { fetchTimeWithoutConversion(ele?.whenUpdated)}</Typography>            ,
          },
        ];
      }, []);



    return (
        <Box>
 <Box display={"flex"} gap={4} mb={6}>
          <TextField
            value={invoiceNumber}
            onChange={updateInvoiceNumber}
            size={"small"}
            placeholder="Query by invoice no."
          />
          <ButtonV1
            title={"Submit"}
            color="primary"
            variant="contained"
            disabled={!invoiceNumber }
            onClick={()=>fetchInvoiceDetailsForInvoice(invoiceNumber)}
          />
          <ButtonV1
            title="Reset"
            variant="outlined"
            disabled={!invoiceNumber}
            onClick={ onFilterReset}
          />
        </Box>

        <Box component={Paper}>
        <TableContainer
          sx={{
            minWidth: 700,
            maxHeight: "calc(100vh - 120px)",
          }}
        >
          <Table stickyHeader>
            <TableHead>
              {tableFields.map((el) => (
                <TableCell key={el.field}>{el.title}</TableCell>
              ))}
            </TableHead>
            <TableBody>
              {isFetching || !invoiceList?.invoiceRejectionDetailsViewList?.length ? (
                <StyledTableRow>
                  <TableCell colSpan={tableFields.length} align="center">
                    {isFetching ? (
                      <Loader sx={{ marginTop: 0 }} />
                    ) : (
                      <Typography textAlign={"center"}>
                        No invoice rejection details found
                      </Typography>
                    )}
                  </TableCell>
                </StyledTableRow>
              ) : (
                invoiceList?.invoiceRejectionDetailsViewList.map((row) => (
                  <StyledTableRow
                    key={row?.ordNumber}
                  >
                    {tableFields?.map((el) => (
                      <TableCell key={el?.field}>{el.render(row)}</TableCell>
                    ))}
                  </StyledTableRow>
                ))
              )}
            </TableBody>
          </Table>
        </TableContainer>
        {!!invoiceList?.invoiceRejectionDetailsViewList?.length && (
          <TablePagination
            rowsPerPageOptions={[ROWS_PER_PAGE]}
            rowsPerPage={ROWS_PER_PAGE}
            page={page}
            count={invoiceList?.totalCount ?? 0}
            onPageChange={onPageChange}
            sx={{ display: "flex", flexDirection: "column-reverse" }}
            ActionsComponent={PaginationAction}
          />
        )}
      </Box>

        </Box>
    )
  
});





export default InvoiceRejectionListView;
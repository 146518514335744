import React from "react";
import { Chip, useTheme } from "@mui/material";

const StatusChip = ({ status }) => {
  const statusData = {
    RELEASE: "Release",
    FULL_HOLD: "Full hold",
    PARTIAL_HOLD: "Partial hold",
  };

  const theme = useTheme();

  const getCustomStyle = () => {
    let style = {};
    switch (status) {
      case "RELEASE":
        style = {
          borderColor: theme.palette.success.dark,
          color: theme.palette.success.dark,
          background: theme.palette.success.light,
        };
        break;
      case "FULL_HOLD":
        style = {
          borderColor: theme.palette.error.dark,
          color: theme.palette.error.dark,
          background: theme.palette.error.errorBgColor,
        };

        break;
      case "PARTIAL_HOLD":
        style = {
          borderColor: theme.palette.warning.dark,
          color: theme.palette.warning.dark,
          background: theme.palette.warning.light,
        };
        break;
      default:
        break;
    }
    return style;
  };

  return (
    <Chip
      label={statusData[status]}
      sx={{
        ...getCustomStyle(),
        borderRadius: 1,
        border: 1,
        borderStyle: "solid",
        fontWeight: 600,
        marginTop: 2,
      }}
    />
  );
};

export default React.memo(StatusChip);

import { Box, Typography, useTheme } from "@mui/material";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@mui/icons-material";
import PropTypes from "prop-types";

import { ButtonV1 } from "components";

const CustomPagination = ({ page, totalCount, onPrevious, onNext }) => {
  const theme = useTheme();

  return (
    <Box
      display="flex"
      alignItems="center"
      justifyContent="space-between"
      padding="10px"
      width="100%"
      margin="auto"
      paddingLeft={0}
      paddingRight={0}
    >
      <ButtonV1
        variant="outlined"
        size="medium"
        title={"Previous"}
        disabled={page <= 1}
        startIcon={<KeyboardArrowLeft />}
        onClick={onPrevious}
      />
      <Box sx={{ display: "flex" }} gap={2}>
        <Box
          sx={{
            border: `1px solid ${theme.palette.grey["400"]}`,
            paddingLeft: 2,
            paddingRight: 2,
            borderRadius: 0.5,
          }}
        >
          {page}
        </Box>
        <Typography>of</Typography>
        <Typography>{totalCount}</Typography>
      </Box>
      <ButtonV1
        variant="outlined"
        size="medium"
        title={"Next"}
        data-testid="next-button"
        disabled={page >= totalCount}
        endIcon={<KeyboardArrowRight />}
        onClick={onNext}
      />
    </Box>
  );
};

export default CustomPagination;

CustomPagination.propTypes = {
  page: PropTypes.number,
  totalCount: PropTypes.number,
  onPrevious: PropTypes.func,
  onNext: PropTypes.func,
};

export const BULK_SHIPMENT = Object.freeze({
  REVIEW_TABLE_HEADER: [
    "Buyer GST",
    "Customer Name (SAP)",
    "SO Number",
    "SO Line Item",
    "Invoice Number",
    "Upload Date",
    "Uploaded By",
    "",
  ],
});

import React from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Typography,
} from "@mui/material";
import { decimalRoundOff, moneyFormat } from "utils";

// Define headers as a constant array
const TABLE_LINE_ITEM_HEADERS = [
  "SKU (ERP)",
  "SO Line",
  "Invoiced Price",
  "Quantity Invoiced",
  "Pending Released Quantity",
  "GST Percentage (%)",
];

const ShipmentLineItem = ({ shipmentDetails }) => {
  const lineItems =
    shipmentDetails?.sapShipmentDetailList?.sapShipmentDetailList;
  return (
    <Paper style={{ marginTop: 20 }}>
      <TableContainer component={Paper}>
        <Table>
          {/* Table Header */}
          <TableHead style={{ backgroundColor: "#f5f5f5" }}>
            <TableRow>
              {TABLE_LINE_ITEM_HEADERS.map((header, index) => (
                <TableCell key={index} style={{ fontWeight: "bold" }}>
                  {header}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          {/* Table Body */}
          <TableBody>
            {!!lineItems?.length &&
              lineItems?.map((row, index) => {
                const pendingReleaseQty =
                  row?.totalDoReleasedQuantity -
                  +row?.totalShippedQuantity -
                  +row?.totalDoShortClosedQuantity;

                const invoicedPrice = row?.lineItemUnitPriceWithTax?.amount;
                return (
                  <TableRow key={index}>
                    <TableCell>{row.skuProduct}</TableCell>
                    <TableCell>{row.soLineItemNumbers.join(",")}</TableCell>
                    <TableCell>
                      {moneyFormat(decimalRoundOff(invoicedPrice))}
                    </TableCell>
                    <TableCell>
                      {`${decimalRoundOff(row.quantity)}  ${
                        row.measurementUnit
                      }`}
                    </TableCell>
                    <TableCell>
                      {`${decimalRoundOff(pendingReleaseQty)}  ${
                        row.measurementUnit
                      }`}
                    </TableCell>
                    <TableCell>
                      {row?.gst ? `${decimalRoundOff(row?.gst * 100)}%` : "-"}
                    </TableCell>
                  </TableRow>
                );
              })}
            {!lineItems?.length && (
              <TableRow>
                <TableCell colSpan={TABLE_LINE_ITEM_HEADERS.length + 1}>
                  <Typography textAlign={"center"}>
                    No line item available
                  </Typography>
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
    </Paper>
  );
};

export default ShipmentLineItem;

import { Box, TextField, Grid, useTheme } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ButtonV1, DropdownV1 } from "components";

import { SHIPMENT_STATUS } from "../../constant";

const CustomTextField = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          label={label}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

const Filters = ({ onFilterSubmit }) => {
  const theme = useTheme();
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      invoiceNumber: "",
      customerGSTIN: "",
      sellerOrderNumber: "",
    },
  });

  const handleFilter = (data) => {
    onFilterSubmit(data); // Pass form values to the parent component
  };

  const handleReset = () => {
    reset(); // Reset the form to default values
    onFilterSubmit({});
  };

  return (
    <Box
      sx={{
        border: `1px solid ${theme.palette.grey["400"]}`,
        borderRadius: 2,
        padding: 3,
      }}
    >
      <Box p={2} as="form" onSubmit={handleSubmit(handleFilter)}>
        <Grid container spacing={2} columns={14}>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"invoiceNumber"}
              label="Invoice number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"customerGSTIN"}
              label="Customer GSTIN"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <CustomTextField
              name={"sellerOrderNumber"}
              label="Seller order number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"orderCategory"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  itemList={["DISTRIBUTED", "PRIVATE_BRAND"]}
                  value={value}
                  onChange={onChange}
                  listType="array"
                  placeholder={"Order category"}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <Controller
              control={control}
              name={"status"}
              render={({ field: { value, onChange } }) => (
                <DropdownV1
                  itemList={Object.values(SHIPMENT_STATUS)}
                  value={value}
                  onChange={onChange}
                  listType="array"
                  placeholder={"Status"}
                />
              )}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <ButtonV1
              title={"Submit"}
              type="submit"
              style={{ width: "100%" }}
              disabled={!isDirty}
            />
          </Grid>
          <Grid item xs={14} md={2}>
            <ButtonV1
              title={"Reset"}
              variant="outlined"
              disabled={!isDirty}
              style={{ width: "100%" }}
              onClick={handleReset}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filters;

Filters.propTypes = {
  onFilterSubmit: PropTypes.func,
  totalRecords: PropTypes.number,
};

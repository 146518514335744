import { Box, Typography, Divider, Grid } from "@mui/material";
import { GridSkeleton } from "CustomStyledComponents";
import { ViewOnly } from "./components";
import {
  decimalRoundOff,
  downloadPdf,
  getFileExtension,
  getSubstring,
  moneyFormat,
  validateNull,
} from "utils";

const GridView = ({ label, fileUrl, fileName }) => {
  const handleDownloadFile = () => {
    if (fileUrl) {
      downloadPdf(fileUrl, fileName);
    }
  };

  return (
    <Grid item xs={2}>
      <Box>
        <Typography style={{ fontSize: 14, lineHeight: 2 }}>
          {`${label} (${fileUrl ? 1 : 0})`}
        </Typography>
        <Typography
          onClick={handleDownloadFile}
          color="primary"
          style={{
            fontWeight: "bold",
            fontSize: 14,
            textDecoration: fileUrl?.length > 1 && "underline",
            cursor: "pointer",
          }}
        >
          {fileName ?? "-"}
        </Typography>
      </Box>
    </Grid>
  );
};

const ShipmentInvoiceDetails = ({ shipmentDetails }) => {
  const lineItems =
    shipmentDetails?.sapShipmentDetailList?.sapShipmentDetailList;

  const invoiceAmountBaseValue = lineItems?.reduce((total, item) => {
    return total + +item?.quantity * +item?.lineItemUnitPriceWithTax?.amount;
  }, 0);

  const invoiceValue = lineItems?.reduce((total, item) => {
    return (
      total +
      +item?.quantity *
        (+item?.lineItemUnitPriceWithTax?.amount +
          +item?.lineItemUnitPriceWithTax?.taxAmount)
    );
  }, 0);

  const invoiceAmountGstOnly = lineItems?.reduce((total, item) => {
    return (
      total +
      +item?.quantity * +item?.lineItemUnitPriceWithTax?.amount * item?.gst
    );
  }, 0);

  const invoiceFile = shipmentDetails?.invoiceUrl
    ? `invoice_${shipmentDetails?.invoiceNumber}.` +
      getFileExtension(shipmentDetails?.invoiceUrl)
    : "-";

  return (
    <Box>
      {/* Row 1 */}
      <GridSkeleton
        style={{ marginTop: 16 }}
        firstChild={
          <ViewOnly
            label="Invoice Number"
            value={validateNull(shipmentDetails?.invoiceNumber)}
          />
        }
        secondChild={
          <ViewOnly
            label="Invoice Value"
            value={moneyFormat(decimalRoundOff(invoiceValue))}
          />
        }
        thirdChild={
          <ViewOnly
            label="Invoiced amount (base value)"
            value={moneyFormat(decimalRoundOff(invoiceAmountBaseValue))}
          />
        }
      />

      {/* Row 2 */}
      <GridSkeleton
        style={{ marginTop: 12 }}
        firstChild={
          <ViewOnly
            label="GST amount"
            value={moneyFormat(decimalRoundOff(invoiceAmountGstOnly))}
          />
        }
      />

      <Divider style={{ marginTop: 10, marginBottom: 10 }} />
      <Grid container spacing={2} columns={10}>
        <GridView
          label="Invoice"
          fileUrl={shipmentDetails?.invoiceUrl}
          fileName={invoiceFile}
        />
        <GridView
          label="E-way bill"
          fileUrl={shipmentDetails?.testEWayBill}
          fileName={getSubstring(shipmentDetails?.testEWayBill, 20)}
        />
        <GridView
          label="Test certificate"
          fileUrl={shipmentDetails?.testCertificate}
          fileName={getSubstring(shipmentDetails?.testCertificate, 20)}
        />
        <GridView
          label="Lorry receipts"
          fileUrl={shipmentDetails?.lorryReceipt}
          fileName={getSubstring(shipmentDetails?.lorryReceipt, 20)}
        />
        <GridView
          label="Other"
          fileUrl={shipmentDetails?.other}
          fileName={getSubstring(shipmentDetails?.other, 20)}
        />
      </Grid>
    </Box>
  );
};

export default ShipmentInvoiceDetails;

import { useEffect, useState } from "react";
import { Box, Button, CircularProgress, Typography } from "@mui/material";
import PropTypes from "prop-types";

const loaderSize = 26;

const ButtonV1 = ({
  title,
  style = {},
  disabled,
  onClick,
  loader,
  textStyle = {},
  variant = "contained",
  color = "secondary",
  size = "medium",
  type = "button",
  boxStyle,
  startIcon,
  endIcon,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(loader);
  }, [loader]);

  const handleClick = async (e) => {
    try {
      setIsLoading(true);
      onClick && (await onClick(e));
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.log("ButtonV1 error", error);
    }
  };

  return (
    <Box
      display={"flex"}
      sx={{ justifyContent: "center", alignItems: "center", ...boxStyle }}
    >
      <Button
        onClick={handleClick}
        size={size}
        type={type}
        color={color}
        startIcon={startIcon}
        endIcon={endIcon}
        variant={variant}
        disabled={disabled || isLoading}
        sx={{
          whiteSpace: "nowrap",
          ...style,
        }}
        {...rest}
      >
        <Typography
          sx={{
            visibility: isLoading ? "hidden" : "visible",
            ...textStyle,
          }}
        >
          {title}
        </Typography>
        {!!isLoading && (
          <CircularProgress
            size={loaderSize}
            sx={{
              position: "absolute",
            }}
          />
        )}
      </Button>
    </Box>
  );
};

ButtonV1.propTypes = {
  title: PropTypes.string.isRequired,
  loader: PropTypes.bool,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  style: PropTypes.object,
  color: PropTypes.string,
  variant: PropTypes.string,
  startIcon: PropTypes.element,
  endIcon: PropTypes.element,
};

export default ButtonV1;

import React from "react";
import { Chip, useTheme } from "@mui/material";
import { KeyboardArrowDown } from "@mui/icons-material";
import { TooltipLabel } from "components";

const StatusChip = ({ status, isTooltip }) => {
  const statusData = {
    PAYMENT_OVERDUE: "Overdue",
    PAYMENT_PENDING: "Pending",
    PAYMENT_FAILED: "Failed",
    PAYMENT_IN_PROGRESS: "In-progress",
    PAYMENT_CONFIRMED: "Confirmed",
    PAYMENT_SUCCESS: "Success",
    PAYMENT_APPROVED: "Approved",
    PAYMENT_ON_HOLD: "On-hold",
    PAYMENT_REJECTED: "Rejected",
    IN_PROCESS: "Request initiated",
    ERP: "ERP",
    OrderBook: "Order book",
    "Order book": "Order book",
    PAYMENT_IN_PROCESS: "Initiated",
    ACCOUNT_VERIFIED: "Verified",
    ACCOUNT_UNVERIFIED: "Not-verified",
  };

  const theme = useTheme();

  const getCustomStyle = (state) => {
    let style = {};
    switch (state) {
      case "PAYMENT_CONFIRMED":
      case "PAYMENT_SUCCESS":
      case "PAYMENT_APPROVED":
      case "ACCOUNT_VERIFIED":
        style = {
          borderColor: "#f3fff3",
          color: "#02b474",
          background: "#f3fff3",
        };
        break;
      case "PAYMENT_OVERDUE":
      case "PAYMENT_ON_HOLD":
        style = {
          borderColor: "#ed6c02",
          color: "#ed6c02",
          background: "#fdf0e6",
        };
        break;
      case "PAYMENT_PENDING":
        style = {
          borderColor: theme.palette.warning.dark,
          color: theme.palette.warning.dark,
          background: "#f7f4e8",
        };

        break;
      case "PAYMENT_FAILED":
      case "PAYMENT_REJECTED":
      case "ACCOUNT_UNVERIFIED":
        style = {
          borderColor: theme.palette.error.dark,
          color: theme.palette.error.dark,
          background: theme.palette.error.errorBgColor,
        };
        break;
      case "PAYMENT_IN_PROGRESS":
        style = {
          borderColor: "#0288d1",
          color: "#0288d1",
          background: "#e6f3fa",
        };
        break;
      case "PAYMENT_IN_PROCESS":
        style = {
          borderColor: "#0288d1",
          color: "#0288d1",
          background: "#e6f3fa",
        };
        break;
      case "ERP":
        style = {
          borderColor: "#0288d1",
          color: theme.palette.text.primary,
          background: theme.shadows,
          fontWeight: "normal",
        };
        break;
      case "OrderBook":
        style = {
          borderColor: "#0288d1",
          color: theme.palette.text.primary,
          background: theme.shadows,
          fontWeight: "normal",
        };
        break;
      default:
        break;
    }
    return style;
  };

  const NextStatus = ({ states }) => {
    return (
      <>
        {states.slice(1).map((ele) => (
          <>
            <Chip
              label={statusData[ele?.state]}
              sx={{
                ...getCustomStyle(ele?.state),
                marginTop: 2,
                "& .MuiChip-deleteIcon": {
                  ...getCustomStyle(),
                },
              }}
              size="small"
              deleteIcon={<KeyboardArrowDown />}
            />
            <br />
          </>
        ))}
      </>
    );
  };

  return (
    <>
      {isTooltip ? (
        statusData[status?.[0]?.state] ? (
          <TooltipLabel
            customStyle={{
              "& .MuiTooltip-tooltip": {
                border: `1px solid ${theme.palette.grey["400"]}`,
              },
              "& .MuiTooltip-arrow": {
                "&::before": {
                  backgroundColor: "white",
                  border: `1px solid ${theme.palette.grey["400"]}`,
                },
              },
            }}
            title={<NextStatus states={status} />}
            labelChildren={
              <Chip
                label={statusData[status?.[0]?.state] || status}
                sx={{
                  ...getCustomStyle(status?.[0]?.state),
                  marginTop: 2,
                  "& .MuiChip-deleteIcon": {
                    ...getCustomStyle(status?.[0]?.state || status),
                  },
                }}
                size="small"
                deleteIcon={<KeyboardArrowDown />}
                onDelete={() => {}}
              />
            }
          />
        ) : (
          "-"
        )
      ) : statusData[status] ? (
        <Chip
          label={statusData[status]}
          sx={{
            ...getCustomStyle(status),
            marginTop: 2,
          }}
          size="small"
        />
      ) : (
        "-"
      )}
    </>
  );
};

export default React.memo(StatusChip);

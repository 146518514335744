import { takeEvery, put, call } from "redux-saga/effects";

import { setTab } from "dataStore/selectedTab";
import {
  setLineItemsAction,
  setOrderDetailsAction,
  updateOrderDetailsAction,
} from "dataStore/orderDetails";
import { verifyLoginAction } from "dataStore/auth";
import { setUserDataAction } from "dataStore/userProfile";
import { displayToasterAction } from "dataStore/showSnackBar";
import { opsSpocList } from "constants";
import { getCall_v2 } from "services";
import { error } from "constants";

const routeMapper = {
  Dashboard: "Dashboard",
  "Order list": "Order list",
  "Incoming payments": "Incoming payments",
  Ledger: "Ledger",
  User_Access_Management: "User management",
  "Ledger correction": "Ledger correction",
  "Finance payout": "Finance payout",
  "Credit update": "Credit limit",
  "Credit Order": "Credit Order",
  "Customer profile": "Customer profile",
  "Customer profile details": "Customer profile details",
  SUPPLY_PANEL: "Supply panel",
  ZOHO_ORDER_VIEW: "Zoho order view",
  INVOICE_REJECTION: "Invoice rejection",
};

function* cleanOrderData() {
  yield put(setOrderDetailsAction({}));
}

function* getRolesAssociatedForUser(action) {
  try {
    const { value } = action.payload;
    if (value) {
      let res = yield call(getCall_v2, "/auth2-service/user/getUserRole_v2");
      res.data["allowed_URL"] = {};
      let allowedModules = res.data.modules;
      // eslint-disable-next-line array-callback-return
      allowedModules?.map((ele) => {
        const path = routeMapper[ele?.name];
        if (path) {
          res.data.allowed_URL[path] = true;
        }
      });

      yield put(setUserDataAction(res.data));

      let orderStateList = [];
      let opsUser = [];

      //filter regions
      // eslint-disable-next-line array-callback-return
      res.data.regions.map((ele) => {
        orderStateList.push(ele?.stateName);
      });

      //filter opsSpoc
      const validOpsUser = opsSpocList?.some(
        (ele) => ele?.label === res.data.name,
      );
      if (validOpsUser) opsUser.push(res.data.name);

      const filterParams = {
        companyName: "",
        orderState: orderStateList?.join() ?? "",
        opsSpoc: opsUser?.join() ?? "",
      };
      const { companyName = "", orderState = "", opsSpoc = "" } = filterParams;
      const queryParam = `?companyName=${companyName}&orderState=${orderState}&opsSpoc=${opsSpoc}`;

      window.location.href = `/dashboard${queryParam} `;
    }
  } catch (err) {
    console.error(`Error in fetching user role ${JSON.stringify(err)}`);
    displayToasterAction({
      value: true,
      message:
        err?.data?.message ??
        "Please try again later, error in fetching user roles",
      error,
    });
    window.location.href = `/`;
  }
}

function* getUpdatedOrderDetails(action) {
  try {
    const orderNumber = action.payload;

    const lineItems = yield call(
      getCall_v2,
      `/oms/line-item/order-number/${orderNumber}`,
    );
    const orderDetails = yield call(
      getCall_v2,
      `/oms/order/v2/number/${orderNumber}`,
    );

    if (orderDetails.data && lineItems.data) {
      yield put(setOrderDetailsAction(orderDetails.data));
      yield put(setLineItemsAction({ lineItemDetails: lineItems.data }));
    }
  } catch (err) {
    console.error(`Error in updating order details and line items`);
  }
}

export default function* rootSaga() {
  yield takeEvery(setTab, cleanOrderData);
  yield takeEvery(verifyLoginAction, getRolesAssociatedForUser);
  yield takeEvery(updateOrderDetailsAction, getUpdatedOrderDetails);
  yield takeEvery(verifyLoginAction, getRolesAssociatedForUser);
}

import { Box, TextField, Grid, Typography } from "@mui/material";
import { Controller, useForm } from "react-hook-form";
import PropTypes from "prop-types";

import { ButtonV1 } from "components";

const CustomTextField = ({ name, label, control }) => {
  return (
    <Controller
      control={control}
      name={name}
      render={({ field: { value, onChange } }) => (
        <TextField
          size="small"
          label={label}
          onChange={onChange}
          value={value}
        />
      )}
    />
  );
};

const Filters = ({ onFilterSubmit, totalRecords = 0 }) => {
  const {
    control,
    reset,
    handleSubmit,
    formState: { isDirty },
  } = useForm({
    mode: "onTouched",
    defaultValues: {
      invoiceNumber: "",
      customerGSTIN: "",
      plantCode: "",
      soNumber: "",
    },
  });

  const handleFilter = (data) => {
    onFilterSubmit(data); // Pass form values to the parent component
  };

  const handleReset = () => {
    reset(); // Reset the form to default values
    onFilterSubmit({});
  };

  return (
    <Box>
      <Typography style={{ fontSize: 16, fontWeight: "bold", marginLeft: 10 }}>
        {`All uploaded records (${totalRecords})`}
      </Typography>
      <Box p={2} as="form" onSubmit={handleSubmit(handleFilter)}>
        <Grid container spacing={2} columns={12}>
          <Grid item xs={14} md={2.2}>
            <CustomTextField
              name={"invoiceNumber"}
              label="Invoice number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2.2}>
            <CustomTextField
              name={"customerGSTIN"}
              label="Customer GSTIN"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2.2}>
            <CustomTextField
              name={"plantCode"}
              label="Plant code"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={2.2}>
            <CustomTextField
              name={"soNumber"}
              label="SO number"
              control={control}
            />
          </Grid>
          <Grid item xs={14} md={1.5}>
            <ButtonV1
              title={"Submit"}
              type="submit"
              style={{ width: "100%" }}
              disabled={!isDirty}
            />
          </Grid>
          <Grid item xs={14} md={1.5}>
            <ButtonV1
              title={"Reset"}
              variant="outlined"
              disabled={!isDirty}
              style={{ width: "100%" }}
              onClick={handleReset}
            />
          </Grid>
        </Grid>
      </Box>
    </Box>
  );
};

export default Filters;

Filters.propTypes = {
  onFilterSubmit: PropTypes.func,
  totalRecords: PropTypes.number,
};

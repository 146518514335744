const currentOrigin = `${window.location.protocol}//${window.location.host}`;

export const API_OAUTH_BASE_URL = `${currentOrigin}/auth2-service`;
// export const JOMS_API_BASE_URL = `${currentOrigin}/joms/api`;
export const JOMS_API_BASE_URL = `${currentOrigin}/joms/api`;
export const OAUTH2_REDIRECT_URI = `${currentOrigin}/oauth2/redirect`;
export const GOOGLE_AUTH_URL = `${API_OAUTH_BASE_URL}/oauth2/authorize/google?redirect_uri=${OAUTH2_REDIRECT_URI}`;
export const OUTLOOK_AUTH_URL = `${API_OAUTH_BASE_URL}/auth/outlook-login?referer=${OAUTH2_REDIRECT_URI}`;

export const sellerOrderTag = "JOO";

export const taxType = {
  GST: "GST",
  IGST: "IGST",
};

export const categoryType = {
  DIRECT: "DIRECT",
  PLANT_SUPPLY: "Plant Supply",
  DISTRIBUTED: "Distributed",
  PRIVATE_BRAND: "Private Brand",
};

export const success = "success";
export const error = "error";
export const warning = "warning";

export const userModule = {
  creditApprover: "Credit Approver",
  newPayments: "New payments",
  oldPayments: "Old payments",
  pendingPayment: "Pending payment",
  doShortCloseApproval: "DO_SHORTCLOSE_APPROVAL",
  shipmentBulkUpload: "SHIPMENT_BULK_UPLOAD",
  shipmentCreationJopl: "SHIPMENT_CREATION_JOPL",
  shipmentCreationJodl: "SHIPMENT_CREATION_JODL",
};

export const orderPaymentTypes = {
  CREDIT: "Credit",
};

export const roleTypes = {
  ROLE_ADMIN: "ROLE_ADMIN",
};

export const reasonTypes = {
  ORDER_REJECTION: "order_rejection",
  ORDER_CANCELLATION: "order_cancellation",
  SHORT_CLOSE: "short_close",
};

export const opsSpocList = [
  { label: "Deepak", value: "Deepak" },
  { label: "Neel", value: "Neel" },
  { label: "Sameer", value: "Sameer" },
  { label: "Shrikant", value: "Shrikant" },
  { label: "Sudha", value: "Sudha" },
  { label: "Prathamesh", value: "Prathamesh" },
  { label: "Jayesh", value: "Jayesh" },
  { label: "Meelen", value: "Meelen" },
  { label: "Samim", value: "Samim" },
  { label: "Mohit", value: "Mohit" },
  {
    label: "Deepakmuthuvel Thiyagarajan",
    value: "Deepakmuthuvel Thiyagarajan",
  },
  { label: "Shubham", value: "Shubham" },
  { label: "Santosh", value: "Santosh" },
  { label: "Bhushan", value: "Bhushan" },
  { label: "Rahul Jaiwal", value: "Rahul Jaiwal" },
  { label: "Vikrant Mankar", value: "Vikrant Mankar" },
  { label: "Amit Yadav", value: "Amit Yadav" },
  { label: "Joshua Sonawane", value: "Joshua Sonawane" },
  { label: "Ganesh Ganave", value: "Ganesh Ganave" },
];

export const salesSpocList = [
  { label: "Abhishek Dusane", value: "Abhishek Dusane" },
  { label: "Abhishek Merchant", value: "Abhishek Merchant" },
  { label: "Aditya", value: "Aditya" },
  { label: "Anbarasan A", value: "Anbarasan A" },
  { label: "Aniket Mane", value: "Aniket Mane" },
  { label: "Aniket Surwade", value: "Aniket Surwade" },
  { label: "Ankit Mistry", value: "Ankit Mistry" },
  { label: "Ankit Verma", value: "Ankit Verma" },
  { label: "Arjun N", value: "Arjun N" },
  { label: "Arjun Sreedhar", value: "Arjun Sreedhar" },
  { label: "Bhatia Sahil", value: "Bhatia Sahil" },
  { label: "Bhavik", value: "Bhavik" },
  { label: "Boris", value: "Boris" },
  { label: "Devarakonda Akhil", value: "Devarakonda Akhil" },
  { label: "Divya Patel", value: "Divya Patel" },
  { label: "Gokula Krishnan", value: "Gokula Krishnan" },
  { label: "Ishwarya Reddy", value: "Ishwarya Reddy" },
  { label: "Jayesh mudaliar", value: "Jayesh mudaliar" },
  { label: "Jayesh Patil", value: "Jayesh Patil" },
  { label: "Joel Biju", value: "Joel Biju" },
  { label: "Karan", value: "Karan" },
  { label: "Karishma", value: "Karishma" },
  { label: "Karthik Sreekumar", value: "Karthik Sreekumar" },
  { label: "Mani Kumar", value: "Mani Kumar" },
  { label: "Manish Sankpal", value: "Manish Sankpal" },
  { label: "Mohanraj", value: "Mohanraj" },
  { label: "Noel Karkada", value: "Noel Karkada" },
  { label: "Omkar Argi", value: "Omkar Argi" },
  { label: "Omkar Narayan Korke", value: "Omkar Narayan Korke" },
  { label: "Pooja Dusane", value: "Pooja Dusane" },
  { label: "Pooja Mali", value: "Pooja Mali" },
  { label: "Poorna Chandran", value: "Poorna Chandran" },
  { label: "Pratik Gaikwad", value: "Pratik Gaikwad" },
  { label: "Rahul Patnaik", value: "Rahul Patnaik" },
  { label: "Sahil Shaikh", value: "Sahil Shaikh" },
  { label: "Sangamesh H J", value: "Sangamesh H J" },
  { label: "Sanket Hosur", value: "Sanket Hosur" },
  { label: "Simon Russel", value: "Simon Russel" },
  { label: "Srushti Sindagi", value: "Srushti Sindagi" },
  { label: "Subham Patnaik", value: "Subham Patnaik" },
  { label: "Suraj Kalambate", value: "Suraj Kalambate" },
  { label: "Suresh Kumar", value: "Suresh Kumar" },
  { label: "Swapnil Nerkar", value: "Swapnil Nerkar" },
  { label: "Tej Deep Reddy", value: "Tej Deep Reddy" },
  {
    label: "Venkatraman Balasubramanian",
    value: "Venkatraman Balasubramanian",
  },
  { label: "Vivek Mohite", value: "Vivek Mohite" },
  { label: "Yajurv Algoter", value: "Yajurv Algoter" },
];

export const URL_PATH = {
  dashboard: "Dashboard",
  "order-list": "Order list",
  "incoming-payments": "Incoming payments",
  ledger: "Ledger",
  "user-management": "User_Access_Management",
  "ledger-correction": "Ledger correction",
  "finance-payout": "Finance payout",
  "credit-limit": "Credit update",
  "credit-orders": "Credit Order",
  "customer-profile": "Customer profile",
  "customer-profile-details": "Customer profile details",
  "supply-panel": "SUPPLY_PANEL",
  "zoho-order-view": "ZOHO_ORDER_VIEW",
  "invoice-rejection": "INVOICE_REJECTION",
};

export const DELETE_USER_CONFIRM_MSG = "Are you sure to delete user?";

export const MoengageEvents = {
  SHIPMENT_DELIVERED: "Shipment-delivered",
  SHIPMENT_DISPATCH: "Shipment-dispatched",
};

export const STATUS_CHIP = {
  success: "SUCCESS",
  warning: "WARNING",
  error: "ERROR",
};

export const CUSTOMER_PORTAL_URL_QA = "https://qa-ssr.msme.jswone.in";
export const CUSTOMER_PORTAL_URL_PROD = "https://www.jswonemsme.com";

export const FINANCE_TAB = {
  pending: "pending",
  historical: "historical",
  upload: "upload",
  approved: "approved",
  pendingPayoutAuth: "Pending-payouts",
  uploadPayoutAuth: "Upload-payouts",
};

export const ORDER_DETAIL_TAB = {
  payouts: "Payouts",
  reconciliation: "Reconciliation",
};

export const paymentTypes = {
  PART: "Part",
  FINAL: "Final",
  POSITIVETOLERANCE: "Positive tolerance",
  ADVANCE: "Advance",
  STAGGERED: "Staggered",
  PLANNEDSHIPMENT: "Planned shipment",
  DO: "DO",
  CREDIT: "Credit",
};
export const paymentStatus = {
  PAYMENT_PENDING: "Payment pending",
  PAYMENT_SUCCESS: "Payment success",
  PAYMENT_CONFIRMED: "Payment confirmed",
};

export const DISPLAY_ATTRIBUTE = {
  sellerName: "companyName",
  deliveryState: "orderState",
  buyerName: "buyerName",
  sellerState: "sellerState",
};

export const DELIVERY_TYPES = {
  jots: "JOTS",
  sellerDelivery: "Seller Delivery",
};

export const BUSINESS_TYPES = [
  {
    state: "MFG",
  },
  {
    state: "MFG - Plant supply",
  },
  {
    state: "Construct",
  },
];

export const displayLabel = {
  today: "Today",
  day_1_3: "1-3 days",
  day_4_7: "4-7 days",
  day_8: "1 week +",
};

export const MAX_FILE_SIZE = 2;
export const JSW = "jsw";
export const ACCESS_TOKEN = "token";
